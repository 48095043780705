import classNames from 'classnames'
import { isFuture } from 'date-fns'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import { RichText } from 'prismic-reactjs'
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import BackButton from 'src/components/back-button'
import { EventCardDetails } from 'src/components/event-card'
import FeaturedEvent from 'src/components/featured-event'
import Highlights from 'src/components/highlights'
import LinkArrow from 'src/components/link-arrow'
import ResponsiveImage from 'src/components/responsive-image'
import { eventsPath } from 'src/paths'
import { BbvPageProps, Image, PrismicEventProps, SpeakerDocument, Video } from 'src/types'
import Play from '../assets/play.svg'
import Layout from '../components/layout'
import * as styles from './event.module.scss'

export const query = graphql`
  query Event($uid: String!) {
    prismicEvent(uid: { eq: $uid }) {
      data {
        title {
          raw
          text
        }
        date
        summary {
          raw
          text
        }
        feature
        image {
          thumbnails {
            large {
              url
              alt
              dimensions {
                height
                width
              }
            }
          }
        }
        hide_image_on_event_page
        feature_image {
          thumbnails {
            large {
              url
              alt
              dimensions {
                height
                width
              }
            }
          }
        }
        video_image {
          thumbnails {
            large {
              url
              alt
              dimensions {
                height
                width
              }
            }
          }
        }
        event_details {
          raw
        }
        rsvp_link {
          url
        }
        duration_minutes
        location_name
        location_address
        location_city_state_zip
        video {
          html
        }
        video_header
        speakers {
          speaker {
            document {
              ... on PrismicSpeaker {
                id
                data {
                  company
                  name {
                    text
                  }
                  title
                  image {
                    url
                  }
                }
              }
            }
          }
        }
        moderator {
          document {
            ... on PrismicSpeaker {
              id
            }
          }
        }
        highlights {
          highlight {
            document {
              ...highlight
            }
          }
        }
      }
    }
    prismicSettings {
      ...settings
    }
    prismicLinks {
      ...links
    }
  }
`

const EventVideo = ({
  video,
  videoImage,
  videoHeader,
}: {
  video: Video
  videoImage: Image | null
  videoHeader: string
}) => {
  const [showVideo, setShowVideo] = useState(!videoImage?.url)
  return (
    <div>
      <div className="h1">{videoHeader || 'Watch the Event'}</div>
      <div className={classNames(styles.videoContainer, { [styles.showVideo]: showVideo })}>
        {!showVideo && (
          <>
            <button onClick={() => setShowVideo(true)} className={styles.videoCover}>
              {!!videoImage?.url && (
                <ResponsiveImage image={videoImage} className={styles.eventImage} />
              )}
              <div className={styles.videoOverlay} />
              <div className={styles.playVideoContainer}>
                <Play />
                Watch Video
              </div>
            </button>
          </>
        )}
        {showVideo && (
          <div className={styles.video} dangerouslySetInnerHTML={{ __html: video.html }} />
        )}
      </div>
    </div>
  )
}

const Speakers = ({
  speakers,
  moderator,
}: {
  speakers: SpeakerDocument[]
  moderator: SpeakerDocument
}) => {
  if (speakers.length === 0) {
    return (
      <div className={styles.noSpeakers}>
        <span>No speakers or moderators added at this time.</span>
      </div>
    )
  }

  return (
    <div>
      {speakers.map((speaker) => {
        const image = speaker.data.image
        const { name, title, company } = speaker.data
        return (
          <div key={speaker.id} className={styles.speaker}>
            {image && (
              <div className={styles.speakerImageContainer}>
                {moderator?.id === speaker.id && <div className={styles.moderator}>Moderator</div>}
                <ResponsiveImage
                  image={image}
                  className={styles.speakerImage}
                  skipFixedRatio={true}
                />
              </div>
            )}
            <div className={styles.speakerInfo}>
              <b>{name.text}</b>
              <br />
              {title}
              <br />
              {company}
            </div>
          </div>
        )
      })}
    </div>
  )
}

const Event = (props: BbvPageProps<PrismicEventProps>) => {
  const { data } = props.data.prismicEvent
  const { uid } = props.pageContext as { uid: string }
  const highlights = data.highlights
    .map((highlight) => highlight.highlight.document)
    .filter((h) => h)

  const speakers = data.speakers.map((s) => s.speaker.document).filter((s) => s)
  const showRsvp = isFuture(new Date(data.date)) && !!data.rsvp_link.url

  return (
    <Layout
      title={data.title.text}
      description={data.summary.text}
      imageUrl={data.image.url}
      pageData={props.data}
    >
      <div className="page-container gray-container">
        <Container>
          <BackButton to={eventsPath()}>Back to events</BackButton>
          <FeaturedEvent uid={uid} event={data} eventPage={true} />

          {!!data.video.html && (
            <>
              <div className={styles.eventSection}>
                <EventVideo
                  video={data.video}
                  videoImage={data.video_image?.thumbnails?.large}
                  videoHeader={data.video_header}
                />
              </div>
            </>
          )}

          {highlights.length > 0 && (
            <>
              <br />
              <br />
              <Highlights highlights={highlights} type="company" hideTitle={true} />
            </>
          )}

          <Row>
            <Col xs={12} sm={6} md={8}>
              <div className={styles.eventSection}>
                <div className="h1">{data.title.text}</div>
                <div className="body-text">
                  <RichText render={data.event_details.raw} />
                </div>
                <br />
                {showRsvp && (
                  <a href={data.rsvp_link.url} className="btn btn-lg btn-primary btn-block">
                    RSVP
                  </a>
                )}
              </div>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <div className={styles.eventSection}>
                <div className="h1">Event Details</div>
                <div className="pt-2">
                  <EventCardDetails event={data} />
                </div>

                {showRsvp && (
                  <div className="mt-4">
                    <LinkArrow href={data.rsvp_link.url} block={true} textLarge={true}>
                      RSVP
                    </LinkArrow>
                  </div>
                )}
              </div>
              <div className={styles.eventSection}>
                <div className="h1">Speakers</div>
              </div>
              <Speakers speakers={speakers} moderator={data.moderator.document} />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default withPreview(Event)
