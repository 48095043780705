// extracted by mini-css-extract-plugin
export const eventSection = "event-module--eventSection--Evck4";
export const videoContainer = "event-module--videoContainer--3jyat";
export const videoCover = "event-module--videoCover--1Peaz";
export const playVideoContainer = "event-module--playVideoContainer--1NUty";
export const showVideo = "event-module--showVideo--Bj9QF";
export const video = "event-module--video--2U0Kv";
export const videoOverlay = "event-module--videoOverlay--2wOua";
export const speaker = "event-module--speaker--3c4k1";
export const speakerInfo = "event-module--speakerInfo--19uhh";
export const speakerImageContainer = "event-module--speakerImageContainer--1sc23";
export const moderator = "event-module--moderator--oHvF8";
export const noSpeakers = "event-module--noSpeakers--22x5G";