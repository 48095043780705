import { Link } from 'gatsby'
import React from 'react'
import ChevronLeft from '../assets/chevron-left.svg'
import * as styles from './back-button.module.scss'
import './layout.scss'

const BackButton = ({ to, children }: { to: string; children: React.ReactNode }) => {
  return (
    <Link to={to} className={styles.backButton}>
      <ChevronLeft />
      {children}
    </Link>
  )
}

export default BackButton
